import React from 'react';
import { graphql } from 'gatsby';

import {
    layout,
    intro,
    title,
    subtitle,
    newspaper,
    prev,
    next,
    catalogs,
    catalogsIntro,
    links,
} from './ingrid-natural-essence.module.scss';
import image1 from '../assets/images/flipbook/Katalog online Ingird Cosmetics_page-0001.jpg';
import image2 from '../assets/images/flipbook/Katalog online Ingird Cosmetics_page-0002.jpg';
import image3 from '../assets/images/flipbook/Katalog online Ingird Cosmetics_page-0003.jpg';
import image4 from '../assets/images/flipbook/Katalog online Ingird Cosmetics_page-0004.jpg';
import image5 from '../assets/images/flipbook/Katalog online Ingird Cosmetics_page-0005.jpg';
import image6 from '../assets/images/flipbook/Katalog online Ingird Cosmetics_page-0006.jpg';
import catalog1 from '../assets/images/flipbook/catalog1.jpg';
import catalog2 from '../assets/images/flipbook/catalog2.jpg';
import catalog3 from '../assets/images/flipbook/catalog3.jpg';
import { ISitePageContext } from '../models/site-page.model';
import { getBreadcrumbItem } from '../utils/get-breadcrumbs';

import SEO from '../components/seo';
import MainLayout from '../layouts/main-layout';
import Title from '../components/atoms/title';
// import Newspaper from '../flipbook/src/components/Newspaper';
import ArrowButton from '../components/atoms/arrow-button';

interface IIngridProps {
    readonly pageContext: ISitePageContext;
}

const IngridNaturalEssence: React.FC<IIngridProps> = ({ pageContext }) => {
    return (
        <>
            <SEO title={'Ingrid Natural Essence'} />
            <MainLayout
                className={layout}
                breadcrumbsConfig={{
                    items: [getBreadcrumbItem(pageContext)],
                }}
            >
                <div className={intro}>
                    <Title Tag={'h1'} className={title}>
                        Ingrid Natural Essence
                    </Title>

                    <p className={subtitle}>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. In commodo urna
                        felis, vel sodales diam pretium nec. Pellentesque ut ligula augue. Duis at
                        placerat erat. Interdum et malesuada fames ac ante ipsum primis in faucibus.
                        Curabitur efficitur, sem et luctus tempor, diam velit consectetur nisi, quis
                        tincidunt orci diam quis mauris. Pellentesque gravida massa erat. Sed at
                        libero et massa mattis efficitur quis eu orci.
                    </p>
                </div>

                {/*<div className={newspaper}>*/}
                {/*    <Newspaper*/}
                {/*        prevButton={{*/}
                {/*            content: <ArrowButton />,*/}
                {/*            className: prev,*/}
                {/*        }}*/}
                {/*        nextButton={{*/}
                {/*            content: <ArrowButton rotateDeg={180} />,*/}
                {/*            className: next,*/}
                {/*        }}*/}
                {/*        mobileBreakpoint={700}*/}
                {/*    >*/}
                {/*        <img src={image1} alt="" />*/}
                {/*        <img src={image2} alt="" />*/}
                {/*        <img src={image3} alt="" />*/}
                {/*        <img src={image4} alt="" />*/}
                {/*        <img src={image5} alt="" />*/}
                {/*        <img src={image6} alt="" />*/}
                {/*    </Newspaper>*/}
                {/*</div>*/}

                <div className={catalogs}>
                    <p className={catalogsIntro}>See our other catalogs</p>
                    <div className={links}>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a href="#">
                            <img src={catalog1} alt="" />
                        </a>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a href="#">
                            <img src={catalog2} alt="" />
                        </a>
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a href="#">
                            <img src={catalog3} alt="" />
                        </a>
                    </div>
                </div>
            </MainLayout>
        </>
    );
};

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;

export default IngridNaturalEssence;
