// extracted by mini-css-extract-plugin
export var catalogs = "ingrid-natural-essence-module--catalogs--wcwVk";
export var catalogsIntro = "ingrid-natural-essence-module--catalogsIntro--OSnVG";
export var intro = "ingrid-natural-essence-module--intro--fWg0b";
export var layout = "ingrid-natural-essence-module--layout--48LBi";
export var links = "ingrid-natural-essence-module--links--lZCDf";
export var newspaper = "ingrid-natural-essence-module--newspaper--criz3";
export var next = "ingrid-natural-essence-module--next--mqJw9";
export var prev = "ingrid-natural-essence-module--prev--9Heic";
export var subtitle = "ingrid-natural-essence-module--subtitle--w-UwW";
export var title = "ingrid-natural-essence-module--title--qBudM";